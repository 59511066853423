import axiosInstance from "./axios";
import config from "./config";
import moment from "moment";
import { getParsedItem } from "../utilities/localstorage";

export const fetchShipments = async (filterConditions, sortBy, sortOrder, page, accountInfo) => {
	try {
		const isCustomerUser = localStorage.getItem("isCustomerUser") === "true";
		const customerName = localStorage.getItem("customerName");

		const payload = {
			SelectedDate: filterConditions?.SelectedDate || moment().format("YYYYMMDD"),
			page: page,
			// Default sorting
			SortBy: sortBy || "DeliveryAppointment",
			SortOrder: sortOrder || "ascending",
		};

		// Always include customer name for customer users
		if (isCustomerUser) {
			payload.CustomerName = customerName;
			payload.isCustomerLoggedIn = isCustomerUser;
		} else if (filterConditions?.CustomerName) {
			payload.CustomerName = filterConditions.CustomerName;
			payload.isCustomerLoggedIn = isCustomerUser;
		}

		if (filterConditions) {
			if (filterConditions.City) payload.City = filterConditions.City;
			if (filterConditions.Warehouse) payload.Warehouse = filterConditions.Warehouse;
			if (filterConditions.DeliveryStatus !== null) {
				payload.DeliveryStatus = filterConditions.DeliveryStatus;
			}
			if (filterConditions.CustomerRegion !== null) {
				payload.CustomerRegion = filterConditions.CustomerRegion;
			}
			if (filterConditions.SearchQuery) {
				const searchQuery = filterConditions.SearchQuery.trim();
				if (searchQuery.startsWith("PONumber:")) {
					payload.PONumber = searchQuery.split(":")[1].trim();
				} else if (searchQuery.startsWith("DeliveryNumber:")) {
					payload.DeliveryNumber = searchQuery.split(":")[1].trim();
				} else if (searchQuery.startsWith("NBNumber:")) {
					payload.NBNumber = searchQuery.split(":")[1].trim();
				}
			}
		}

		if (sortBy) {
			payload.SortBy = sortBy;
			payload.SortOrder = sortOrder;
		}

		const response = await axiosInstance.post(config.endpoints.shipment_data_url, payload);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 404) {
			return { shipments: [], hasMore: false, nextPage: null };
		} else {
			throw new Error("Failed to fetch shipments");
		}
	} catch (error) {
		console.error("Error fetching shipments:", error);
		throw error;
	}
};

export const getCachedisCustomerUser = () => getParsedItem("isCustomerUser");
export const getCachedCustomerName = () => getParsedItem("customerName");
export const getCachedCustomerUsername = () => getParsedItem("username");
