import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { Grid, Select, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { updateShipment } from "../../api/updateShipmentTable";
import { fetchReasonCodes } from "../../api/resoncode";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useThemeDark from "@mui/material/styles/useTheme";
import { useToastContext } from "../../context/toast";

export default function CustomizedDialogs({ open, handleClose, rowData, onSave, loggedInUser }) {
	const theme = useTheme();
	const themeDark = useThemeDark();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { showErrorToast } = useToastContext();

	const [ReasonCode, setReasonCode] = useState("");
	const [Notes, setNotes] = useState("");
	const [PickupEta, setPickupEta] = useState(null);
	const [PickupTime, setPickupTime] = useState(null);
	const [DeliveryEta, setDeliveryEta] = useState(null);
	const [DeliveryTime, setDeliveryTime] = useState(null);
	const [RescheduleDate, setRescheduleDate] = useState(null);
	const [pickupError, setPickupError] = useState("");
	const [showPickupError, setShowPickupError] = useState(false);
	const [updatedRow, setUpdatedRow] = useState(null);
	const [reasonCodes, setReasonCodes] = useState([]);
	const [showDeliveryError, setShowDeliveryError] = useState(false);

	// Validation states
	const isLateOrMissedOrOnTime =
		rowData?.DeliveryStatus === "Late" ||
		rowData?.DeliveryStatus === "Missed" ||
		rowData?.DeliveryStatus === "On Time";

	const dateTimePickerStyle = {
		width: "100%",
		"& .MuiOutlinedInput-root": {
			width: "100%",
			"&.Mui-error .MuiOutlinedInput-notchedOutline": {
				borderColor: themeDark.palette.border.main,
			},
		},
		"& .MuiInputBase-input": {
			width: "100%",
		},
	};

	const deliveryDatePickerStyle = {
		...dateTimePickerStyle,
		"& .MuiOutlinedInput-root": {
			...dateTimePickerStyle["& .MuiOutlinedInput-root"],
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: showDeliveryError ? "#d32f2f" : themeDark.palette.border.main,
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: showDeliveryError ? "#d32f2f" : themeDark.palette.border.main,
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: showDeliveryError ? "#d32f2f" : themeDark.palette.FocusStyle.main,
			},
		},
	};

	const rescheduleDatePickerStyle = {
		...dateTimePickerStyle,
		"& .MuiOutlinedInput-root": {
			...dateTimePickerStyle["& .MuiOutlinedInput-root"],
			"& .MuiOutlinedInput-notchedOutline": {},
			"&:hover .MuiOutlinedInput-notchedOutline": {},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {},
		},
	};

	const handleReasonCodeChange = (event) => {
		setReasonCode(event.target.value);
	};

	const pickupDatePickerStyle = {
		...dateTimePickerStyle,
		"& .MuiOutlinedInput-root": {
			...dateTimePickerStyle["& .MuiOutlinedInput-root"],
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: showPickupError ? "#d32f2f" : themeDark.palette.border.main,
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: showPickupError ? "#d32f2f" : themeDark.palette.border.main,
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: showPickupError ? "#d32f2f" : themeDark.palette.FocusStyle.main,
			},
		},
	};

	const handlePickupEtaChange = (newValue) => {
		if (rowData.ShipmentDetails?.PickupStatus === "Picked up") {
			setShowPickupError(true);
			// setPickupError("Pickup ETA cannot be modified for picked-up shipments");
			showErrorToast("Pickup ETA cannot be Modified");
			// Clear the pickup ETA and errors after delay
			setTimeout(() => {
				setPickupEta(null);
				setShowPickupError(false);
				setPickupError("");
			}, 2500);
		} else {
			setPickupEta(newValue);
			setPickupError("");
			setShowPickupError(false);
		}
	};

	const ITEM_HEIGHT = 60;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	useEffect(() => {
		if (updatedRow) {
			onSave(updatedRow);
		}
	}, [updatedRow, onSave]);

	const handleNotesChange = (event) => {
		setNotes(event.target.value);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchReasonCodes();
				const uniqueReasonCodes = Array.from(new Set(data.map((code) => code.description)));
				const sortedReasonCodes = uniqueReasonCodes.sort((a, b) => a.localeCompare(b));
				setReasonCodes(["", ...sortedReasonCodes]);
			} catch (error) {
				console.error("Error fetching reasonCodes:", error);
				setReasonCodes([]);
			}
		};

		fetchData();
	}, []);

	const handleclose = () => {
		setPickupError("");
		setPickupEta(null);
		setShowPickupError(false);
		handleClose();
	};

	useEffect(() => {
		if (rowData) {
			setReasonCode(rowData.ReasonCode || "");
			setNotes(rowData.Notes || "");
			setPickupEta(
				rowData.ShipmentDetails?.PickupEta
					? moment(rowData.ShipmentDetails?.PickupEta, "YYYYMMDDHHmmss")
					: null,
			);
			setPickupTime(
				rowData.ShipmentDetails.PickupTime
					? moment(rowData.ShipmentDetails?.PickupTime, "YYYYMMDDHHmmss")
					: null,
			);
			setDeliveryEta(rowData.DeliveryEta ? moment(rowData.DeliveryEta, "YYYYMMDDHHmmss") : null);
			setDeliveryTime(rowData.DeliveryTime ? moment(rowData.DeliveryTime, "YYYYMMDDHHmmss") : null);
			setRescheduleDate(
				rowData.RescheduleDate ? moment(rowData.RescheduleDate, "YYYYMMDDHHmmss") : null,
			);
		}
	}, [rowData]);

	const handleSave = async () => {
		let hasError = false;
		let message = "";
		let clearPickup = false;
		let clearDelivery = false;
		// Check for Picked up status
		if (rowData?.PickupStatus === "Picked Up" && PickupEta) {
			message = "Pickup ETA cannot be Modified";
			setShowPickupError(true);
			clearPickup = true;
			hasError = true;
		}

		if (isLateOrMissedOrOnTime) {
			// Check both conditions
			const hasPickupEtaError = PickupEta !== null;
			const hasDeliveryEtaError = DeliveryEta !== null;
			if (hasPickupEtaError && hasDeliveryEtaError) {
				message = "Pickup ETA and Delivery ETA cannot be Modified";
				setShowPickupError(true);
				setShowDeliveryError(true);
				clearPickup = true;
				clearDelivery = true;
				hasError = true;
			} else if (hasPickupEtaError) {
				message = "Pickup ETA cannot be Modified";
				setShowPickupError(true);
				clearPickup = true;
				hasError = true;
			} else if (hasDeliveryEtaError) {
				message = "Delivery ETA cannot be Modified";
				setShowDeliveryError(true);
				clearDelivery = true;
				hasError = true;
			}
		}

		if (hasError) {
			showErrorToast(message);
			// Reset error states after delay
			setTimeout(() => {
				// Clear immediately
				if (clearPickup) setPickupEta(null);
				if (clearDelivery) setDeliveryEta(null);
				setShowPickupError(false);
				setShowDeliveryError(false);
			}, 2500);
			return;
		}
		const payload = {
			Notes: Notes || "",
			DeliveryNumber: rowData?.DeliveryNumber || "",
			NBNumber: rowData?.NBNumber || "",
			StopSequence: rowData?.StopSequence ? rowData.StopSequence.toString() : "",
			PickupEta: PickupEta ? PickupEta.format("YYYYMMDDHHmmss") : "",
			PickupTime: PickupTime ? PickupTime.format("YYYYMMDDHHmmss") : "",
			DeliveryEta: DeliveryEta ? DeliveryEta.format("YYYYMMDDHHmmss") : "",
			DeliveryTime: DeliveryTime ? DeliveryTime.format("YYYYMMDDHHmmss") : "",
			ReasonCode: ReasonCode === "" ? null : ReasonCode,
			RescheduleDate: RescheduleDate ? RescheduleDate.format("YYYYMMDDHHmmss") : "",
			User: loggedInUser || "",
		};

		try {
			const updatedShipmentData = await updateShipment(payload);
			const updatedShipmentList = updatedShipmentData?.shipments[0];

			const updatedRowData = {
				...updatedShipmentList,
				ShipmentDetails: {
					...updatedShipmentList?.ShipmentDetails,
				},
				Notes: Notes || "",
				ReasonCode: updatedShipmentList?.ShipmentDetails.ReasonCode,
				PickupEta: updatedShipmentList?.ShipmentDetails.PickupEta,
				DeliveryEta: updatedShipmentList?.ShipmentDetails.DeliveryEta,
				DeliveryTime: updatedShipmentList?.ShipmentDetails.DeliveryTime,
				UpdatedBy: loggedInUser,
				CreatedTime: updatedShipmentList?.ShipmentDetails.CreatedTime,
				UpdatedTime: updatedShipmentList?.ShipmentDetails.UpdatedTime,
				RescheduleDate: updatedShipmentList?.ShipmentDetails.RescheduleDate,
			};

			onSave(updatedRowData);
			handleClose();
		} catch (error) {
			console.error("Error updating shipment:", error);
			showErrorToast("Error updating shipment");
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			aria-labelledby="customized-dialog-title"
			fullWidth
			maxWidth="lg"
			sx={{
				padding: isSmallScreen ? "20px" : "40px",
			}}
		>
			<DialogTitle component="div">
				<Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
					More Information
				</Typography>
			</DialogTitle>

			<DialogContent>
				<Box
					style={{
						background: themeDark.palette.background.default,
						borderRadius: 8,
						padding: 16,
					}}
				>
					<Grid container spacing={2} marginBottom={2}>
						{[
							{ label: "NB Number:", value: rowData.NBNumber },
							{ label: "Customer:", value: rowData.CustomerName },
							{
								label: "Transport Mode:",
								value: rowData.ShipmentDetails.TransportMode,
							},
							{
								label: "Customer Region:",
								value: rowData.ShipmentDetails.CustomerRegion,
							},
							{
								label: "Original Request Date:",
								value: rowData.ShipmentDetails?.OriginalRequestDate?.includes("-")
									? rowData.ShipmentDetails?.OriginalRequestDate
									: rowData.ShipmentDetails?.OriginalRequestDate
										? moment(rowData.ShipmentDetails?.OriginalRequestDate, "YYYYMMDDHHmmss").format(
												"MM/DD/YYYY HH:mm:ss",
											)
										: "",
							},
							{ label: "Customer ID:", value: rowData.ShipmentDetails?.DestinationLocationGID },
							{
								label: "Data Source:",
								value: rowData.ShipmentDetails.DataSource,
							},
							{ label: "Phone Number:", value: rowData.ContactDetails },
							{ label: "Commodity:", value: rowData.ShipmentDetails?.Commodity },
						].map((item) => (
							<Grid item xs={12} sm={6} md={3} key={item.label}>
								<Typography
									sx={{
										fontFamily: "Poppins, sans-serif",
										fontWeight: "medium",
									}}
									variant="body1"
								>
									{item.label}
								</Typography>
								<Typography>{item.value}</Typography>
							</Grid>
						))}
					</Grid>

					<Typography
						variant="h6"
						sx={{
							fontFamily: "Poppins, sans-serif",
							fontWeight: "bold",
							marginBottom: 2,
						}}
					>
						Edit Information:
					</Typography>

					<Grid container spacing={2} marginBottom={2}>
						<Grid item xs={12} sm={6}>
							<Box sx={{ minWidth: 120 }}>
								<FormControl fullWidth>
									<InputLabel
										sx={{
											color: theme.palette.text.primary,
											"&.Mui-focused": {
												color: theme.palette.FocusStyle.main,
											},
										}}
										id="demo-simple-select-label"
									>
										Reason Code
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={ReasonCode}
										label="Reason Code"
										onChange={handleReasonCodeChange}
										MenuProps={MenuProps}
										sx={{
											padding: "8px",
											borderRadius: "4px",
											fontFamily: "Poppins, sans-serif",
											color: theme.palette.text.primary,
											"& fieldset": {
												border: theme.palette.border.main,
											},
											"&:hover .MuiOutlinedInput-notchedOutline": {
												borderColor: theme.palette.border.main,
											},
											"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
												borderColor: theme.palette.FocusStyle.main,
											},
										}}
									>
										{reasonCodes.length > 0 ? (
											reasonCodes.map((code) => (
												<MenuItem key={code} value={code}>
													{code === "" ? "BLANK" : code}
												</MenuItem>
											))
										) : (
											<MenuItem disabled>No reason codes found</MenuItem>
										)}
									</Select>
								</FormControl>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box sx={{ width: "100%", height: "100%" }}>
								<OutlinedInput
									placeholder="Notes"
									fullWidth
									value={Notes}
									onChange={handleNotesChange}
									sx={{
										"&.Mui-focused": {
											border: themeDark.palette.border.main,
										},
									}}
								/>
							</Box>
						</Grid>
					</Grid>

					<Typography
						sx={{
							fontFamily: "Poppins, sans-serif",
							marginBottom: 2,
							marginTop: 2,
						}}
						variant="h6"
					>
						Pickup:
					</Typography>

					<Grid container spacing={2} marginBottom={2}>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									label="Pickup ETA"
									value={PickupEta}
									onChange={handlePickupEtaChange}
									renderInput={(params) => (
										<OutlinedInput {...params} fullWidth error={showPickupError} />
									)}
									inputFormat="MM/DD/YYYY HH:mm"
									sx={pickupDatePickerStyle}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									label="Pickup Time"
									value={PickupTime}
									onChange={(newValue) => setPickupTime(newValue)}
									renderInput={(params) => <OutlinedInput {...params} />}
									inputFormat="MM/DD/YYYY HH:mm"
									sx={dateTimePickerStyle}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>

					<Typography
						sx={{
							fontFamily: "Poppins, sans-serif",
							marginBottom: 2,
							marginTop: 2,
						}}
						variant="h6"
					>
						Delivery:
					</Typography>

					<Grid container spacing={2} marginBottom={2}>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									label="Delivery ETA"
									value={DeliveryEta}
									onChange={(newValue) => setDeliveryEta(newValue)}
									renderInput={(params) => (
										<OutlinedInput {...params} fullWidth error={showDeliveryError} />
									)}
									inputFormat="MM/DD/YYYY HH:mm"
									sx={deliveryDatePickerStyle}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									label="Delivery Time"
									value={DeliveryTime}
									onChange={(newValue) => setDeliveryTime(newValue)}
									renderInput={(params) => <OutlinedInput {...params} />}
									inputFormat="MM/DD/YYYY HH:mm"
									sx={dateTimePickerStyle}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>

					<Typography
						sx={{
							fontFamily: "Poppins, sans-serif",
							marginBottom: 2,
							marginTop: 2,
						}}
						variant="h6"
					>
						Reschedule:
					</Typography>

					<Grid container spacing={2} marginBottom={2}>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									label="Reschedule Date"
									value={RescheduleDate}
									onChange={(newValue) => {
										setRescheduleDate(newValue);
									}}
									inputFormat="MM/DD/YYYY HH:mm"
									sx={rescheduleDatePickerStyle}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					sx={{ textTransform: "none", color: themeDark.palette.text.primary }}
					onClick={handleclose}
				>
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					autoFocus
					sx={{
						backgroundColor: themeDark.palette.button.main,
						color: "white",
						textTransform: "none",
						"&:hover": {
							backgroundColor: theme.palette.buttonhover.main,
						},
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
